

$custom: #71b6f9;  //lighter blue


// Color variables

$white: #ffffff;


$primary: #188ae2; 		//light blue
$secondary: #727b84; 	// mid gray
$success: #10c469; 		// success green
$info: #35b8e0; 		//blueish
$warning: #f9c851; 		// yellowish warning
$danger: #ff5b5b;
$purple: #5b69bc;
$pink: #ff8acc;
$inverse: #3b3e47;
$muted: #98a6ad;
$light: #eeeeee;
$dark: #435966;
$lightdark3: #333333;
$lightdark4: #444444;
$lightdark5: #555555;
$lightdark7: #797979;
$light5: #ebeff2;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark-alt: #cccccc;
$lightdark: #f4f8fb;
$lightgray: #7a8c9a;
$blue-primary-color: #0075FF;
$green-primary-color: #0DB9A4;
$warning-primary-color: #DCAF0E;
$kyc-primary-color: #F94F06;


// Width variables
$width: 100%;

// Height variables
$height: 100%;

// Font size 
$base-font-size: 14px;


// Leftbar background 
$bg-leftbar : #2f3e47;

//Font variables
$font-primary: 'Roboto', sans-serif;
$font-secondary:  'Karla', sans-serif;
